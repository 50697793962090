
import { useStore } from "vuex";
import { defineComponent, ref, onMounted } from "vue";
import moment from "moment";
import { Actions } from "@/store/enums/StoreEnums";
import { useRoute, useRouter } from "vue-router";

interface RiderDetailModel {
  users: RiderInfo;
  users_info: RiderVahicle;
  users_info_image: RiderDocument;
}

interface RiderInfo {
  id: number;
  users_id: string;
  users_name: string;
  users_first_name: string;
  users_last_name: string;
  users_email: string;
  users_phone: string;
  users_create_by: string;
  users_ubdate_by: string;
  created_at: string;
  update_at: string;
  user_pdpa_status: number;
  users_original_tel: string;
  password: string;
  user_status: number;
}

interface RiderVahicle {
  id: number;
  ri_user_code: string;
  ri_vehicle_brand: string;
  ri_vehicle_model: number;
  ri_vehicle_model_name: string;
  ri_vehicle_model_year: string;
  ri_plate_number: string;
  ri_order_status: boolean;
  ri_motor_box: boolean;
  ri_drivg_licent_no: string;
  ri_id_card_no: string;
  ri_registerbook: string;
  ri_district_id: string;
  ri_bookbank_no: string;
  ri_bookbank_name: string;
  ri_bookbank_brand: number;
  ri_users_id: number;
  ri_provinces_id: number;
}

interface RiderDocument {
  ri_driving_license_front_img: string;
  ri_plate_number_img: string;
  ri_id_card_img: string;
  ri_registerbook_img: string;
  ri_profile_img: string;
  ri_book_bank_img: string;
  ri_phorabo_img: string;
}

export default defineComponent({
  setup() {
    const store = useStore();
    const isLoading = ref(false);
    const route = useRoute();
    const router = useRouter();
    const riderDetail = ref({
      users_info_image: {},
      users: {},
      users_info: {},
    } as RiderDetailModel);

    async function init() {
      isLoading.value = true;
      const result = await store.dispatch(
        `RiderModule/${Actions.FETCH_RIDER_BY_ID}`,
        route.params.rider_id
      );
      isLoading.value = false;
      if (result == false) return;
      riderDetail.value = result;
    }

    onMounted(() => {
      init();
    });

    return {
      moment,
      isLoading,
      riderDetail,
      router,
    };
  },
});
